'use client';

import { ChakraProvider } from '@chakra-ui/react';
import { customTheme } from '@/theme';
import { ColorModeProvider } from './color-mode';

export function Provider({ children, forcedTheme }: { children: React.ReactNode; forcedTheme?: 'light' | 'dark' }) {
	return (
		<ChakraProvider value={customTheme}>
			<ColorModeProvider forcedTheme={forcedTheme}>{children}</ColorModeProvider>
		</ChakraProvider>
	);
}
