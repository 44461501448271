'use client';

import { useEffect, useState } from 'react';
import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';

// TODO DATEPICKER
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { registerLocale } from 'react-datepicker';
import { cs } from 'date-fns/locale/cs';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import * as CookieConsent from 'vanilla-cookieconsent';
import { useLocale } from 'next-intl';
import { User } from '@shared/zod-schemas';

import { AuthContextProvider } from '@/supabase/AuthContext';
import { errorToast } from '@/utils/toast';

import 'vanilla-cookieconsent/dist/cookieconsent.css';

registerLocale('cs', cs);

export function ClientProviders({ children, user }: { children: React.ReactNode; user?: User }) {
	const locale = useLocale();
	const [queryClient] = useState(
		() =>
			new QueryClient({
				queryCache: new QueryCache({
					onError: (error) => {
						errorToast('Nastala chyba');
						console.error('Error in query cache', JSON.stringify(error));
					},
				}),
				defaultOptions: {
					queries: {
						// TODO REACT-QUERY tohle prestalo byt v typech v react-query v5
						// prijde mne ale ze to funguje spravne
						// https://github.com/TanStack/query/issues/8218
						// eslint-disable-next-line @typescript-eslint/ban-ts-comment
						// @ts-ignore
						getNextPageParam: (lastPage: any) => lastPage?.nextCursor ?? undefined,
						refetchOnWindowFocus: false,
					},
				},
			}),
	);

	useEffect(() => {
		CookieConsent.run({
			categories: {
				necessary: {
					enabled: true, // this category is enabled by default
					readOnly: true, // this category cannot be disabled
				},
				analytics: {},
			},

			language: {
				default: locale,
				translations: {
					en: {
						consentModal: {
							title: 'We use cookies',
							description:
								'TaskGrip s.r.o., Company ID 21511306, uses cookies and other data to ensure the proper functionality of our website. With your consent, we also use them to personalize content and improve your user experience. By clicking "I Understand," you agree to the processing of this information, including its sharing for targeted advertising on social media and other websites.',
							acceptAllBtn: 'Accept all',
							acceptNecessaryBtn: 'Reject all',
							showPreferencesBtn: 'Manage Individual preferences',
						},
						preferencesModal: {
							title: 'Manage cookie preferences',
							acceptAllBtn: 'Accept all',
							acceptNecessaryBtn: 'Reject all',
							savePreferencesBtn: 'Accept current selection',
							closeIconLabel: 'Close modal',
							sections: [
								{
									title: 'Somebody said ... cookies?',
									description: 'I want one!',
								},
								{
									title: 'Strictly Necessary cookies',
									description:
										'These cookies are essential for the proper functioning of the website and cannot be disabled.',
									linkedCategory: 'necessary',
								},
								{
									title: 'Performance and Analytics',
									description:
										'These cookies collect information about how you use our website. All of the data is anonymized and cannot be used to identify you.',
									linkedCategory: 'analytics',
								},
								{
									title: 'More information',
									description:
										'For any queries in relation to my policy on cookies and your choices, please contact us',
								},
							],
						},
					},
					cz: {
						consentModal: {
							title: 'Používáme cookies',
							description:
								'Společnost TaskGrip s.r.o., IČO 21511306, využívá soubory cookies a další data pro zajištění správné funkce našeho webu. S Vaším souhlasem je také používáme k přizpůsobení obsahu a zlepšení uživatelského zážitku. Kliknutím na „Přijmout vše“ souhlasíte s tím, že můžeme tyto informace zpracovávat, včetně jejich sdílení za účelem zobrazování cílené reklamy na sociálních sítích a dalších webových stránkách.',
							acceptAllBtn: 'Přijmout vše',
							acceptNecessaryBtn: 'Odmítnout vše',
							showPreferencesBtn: 'Spravovat individuální nastavení',
						},
						preferencesModal: {
							title: 'Správa preferencí cookies',
							acceptAllBtn: 'Přijmout vše',
							acceptNecessaryBtn: 'Odmítnout vše',
							savePreferencesBtn: 'Přijmout aktuální výběr',
							closeIconLabel: 'Zavřít modal',
							sections: [
								{
									title: 'Někdo řekl ... cookies?',
									description: 'Jednu si dám!',
								},
								{
									title: 'Striktně nutné cookies',
									description:
										'Tato cookies jsou nezbytná pro správné fungování webu a nelze je vypnout.',
									linkedCategory: 'necessary',
								},
								{
									title: 'Výkon a analýza',
									description:
										'Tato cookies shromažďují informace o tom, jak používáte náš web. Všechna data jsou anonymizovaná a nelze je použít k vaší identifikaci.',
									linkedCategory: 'analytics',
								},
								{
									title: 'Další informace',
									description:
										'Pro dotazy týkající se mé politiky o cookies a vašich možností, prosím kontaktujte nás</a>',
								},
							],
						},
					},
				},
			},
		});
	}, [locale]);

	return (
		<AuthContextProvider user={user}>
			<QueryClientProvider client={queryClient}>
				{children}
				{process.env.NODE_ENV !== 'production' && <ReactQueryDevtools initialIsOpen={false} />}
			</QueryClientProvider>
		</AuthContextProvider>
	);
}
