'use client';

import { useParams } from 'next/navigation';
import { Flex, HStack, VStack, Text, Box } from '@chakra-ui/react';
import { FiChevronDown } from 'react-icons/fi';
import { useTranslations } from 'next-intl';
import { useGetUserRolesByOwnerId } from '@shared/client-api';
import { useUser } from '@/supabase/AuthContext';
import { useRouter } from '@/i18n/routing';
import { signOut } from '@/app/actions';

import { MenuContent, MenuItem, MenuRoot, MenuTrigger } from '@/components/ui/menu';
import { Avatar } from '@/components/ui/avatar';

interface NavUserPopoverProps {
	isApp?: boolean;
}

function NavUserPopover({ isApp = true }: NavUserPopoverProps) {
	const router = useRouter();
	const n = useTranslations('Nav');
	const params = useParams();

	const user = useUser();
	const { data: userRole } = useGetUserRolesByOwnerId(
		{
			q: {
				where: {
					userId: user?.id,
					ownerId: params.ownerId as string,
				},
			},
		},
		{
			query: { enabled: !!params?.ownerId },
		},
	);

	return (
		<Flex alignItems="center">
			<MenuRoot
				positioning={{
					placement: 'bottom-end',
				}}
			>
				<MenuTrigger py={2} transition="all 0.3s" _focus={{ boxShadow: 'none' }}>
					<HStack>
						{user && (
							<Avatar
								size={isApp ? 'sm' : 'md'}
								name={user.name || user.email || ''}
								src={user.image || ''}
							/>
						)}
						{isApp ? (
							<>
								<VStack display={{ base: 'none', md: 'flex' }} alignItems="flex-start" gap="1px" ml="2">
									<Text textStyle="sm">{user?.name || user?.email || ''}</Text>
								</VStack>
								<Box display={{ base: 'none', md: 'flex' }}>
									<FiChevronDown />
								</Box>
							</>
						) : null}
					</HStack>
				</MenuTrigger>
				<MenuContent bg="bgMenu" borderColor="border">
					{!isApp && (
						<MenuItem value="dashboard" onClick={() => router.push('/app')}>
							{n('profileDropdown.dashboard')}
						</MenuItem>
					)}
					<MenuItem value="profile" onClick={() => router.push('/app/profile')}>
						{n('profileDropdown.profil')}
					</MenuItem>
					<MenuItem value="owners" onClick={() => router.push('/app/owners')}>
						{n('profileDropdown.owners')}
					</MenuItem>
					<MenuItem value="settings" onClick={() => router.push('/app/settings')}>
						{n('profileDropdown.settings')}
					</MenuItem>
					{params.ownerId && userRole?.[0]?.employeeId && (
						<MenuItem
							value="myData"
							onClick={() =>
								router.push(`/app/owners/${params.ownerId}/employees/${userRole?.[0]?.employeeId}`)
							}
						>
							{n('profileDropdown.myData')}
						</MenuItem>
					)}
					<MenuItem value="changePassword" onClick={() => router.push('/app/change-password')}>
						{n('profileDropdown.changePassword')}
					</MenuItem>
					<MenuItem
						value="logOut"
						onClick={() => {
							// eslint-disable-next-line @typescript-eslint/ban-ts-comment
							// @ts-ignore
							if (window?.google) {
								// eslint-disable-next-line @typescript-eslint/ban-ts-comment
								// @ts-ignore
								window?.google?.accounts?.id?.disableAutoSelect?.();
							}
							signOut({ callbackUrl: '/login' });
						}}
					>
						{n('profileDropdown.logOut')}
					</MenuItem>
				</MenuContent>
			</MenuRoot>
		</Flex>
	);
}

export default NavUserPopover;
